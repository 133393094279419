import React from 'react'
import { graphql, Link } from 'gatsby'
import { makeStyles } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Master from '../components/Master'
import Products from '../components/ListView/Products/Products'

const Index = ({ data }) => {
    console.log('Data', data)
    const page = data.page
    const products = data.allProduct.nodes
    const events = data.allEvent.nodes
    const MAIN_BACKGROUND_COLOR = data.MAIN_BACKGROUND_COLOR.value
    const MENU_COLOR = data.MENU_COLOR.value

    const useStyles = makeStyles(theme => ({
        center: {
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex'
        },
        image: {
            width: '100px'
        },
        imageOnMediaQuery: {
            width: '100px'
        },
        button: {
            marginTop: '30px',
            padding: '10px',
            backgroundColor: `${MAIN_BACKGROUND_COLOR}`,
            color: `${MENU_COLOR}`,
            textDecoration: 'none',
            fontSize: '1.3rem',
            borderRadius: '5px'
        }
    }));

    const classes = useStyles();

    const imageMediaQuery = useMediaQuery('(min-width:100px)');

    let image = <img className={classes.image} src={`${process.env.SITE_IMAGE}`} title={page.title} alt={page.title} />
    if (!imageMediaQuery) {
        image = <img className={classes.imageOnMediaQuery} src={`${process.env.SITE_IMAGE}`} title={page.title} alt={page.title} />
    }

    return (
        <Master title={page.title}>
            <GatsbySeo
                title={page.title}
                description={page.description}
                canonical={`${process.env.SITE_URL}`}
                noindex={false}
                nofollow={false}
                openGraph={{
                    url: `${process.env.SITE_URL}`,
                    title: page.title,
                    description: page.description,
                    images: [
                        {
                            url: `${process.env.SITE_IMAGE_OG_512}`,
                            width: 512,
                            height: 512,
                            alt: page.title
                        }
                    ],
                    site_name: `${process.env.SITE_NAME}`,
                }}
                twitter={{
                    handle: '@handle',
                    site: '@site',
                    cardType: 'summary_large_image',
                }}
            />
            <h2 className={classes.center}>Festas em destaque</h2>
            <Products products={events} />
            <div className={classes.center}>
                <Link className={classes.button} to="/festas">veja todas as festas</Link>
            </div>
            <h2 className={classes.center}>Personalizados em destaque</h2>
            <Products products={products} />
            <div className={classes.center}>
                <Link className={classes.button} to="/personalizados">veja todos os personalizados</Link>
            </div>
            <h1 className={classes.center}>
                {image}
            </h1>
        </Master>
    )
}

export default Index

export const query = graphql`
    query ($pageId: String!) {
        page(id: {eq: $pageId}) {
            title
            description
            keywords
        }
        allProduct(sort: {fields: updatedAt, order: DESC}, filter: {isFeatured: {eq: true}}) {
          nodes {
            id
            title
            slug
            description
            price
            image            
            imageThumbnail
            isEvent
          }
        }
        allEvent(sort: {fields: updatedAt, order: DESC}, filter: {isFeatured: {eq: true}}) {
          nodes {
            id
            title
            slug
            description
            price
            image
            imageThumbnail
            isEvent
          }
        }
        MAIN_BACKGROUND_COLOR: setting(environmentName: {eq: "MAIN_BACKGROUND_COLOR"}) {
            value
        }
        MENU_COLOR: setting(environmentName: {eq: "MENU_COLOR"}) {
            value
        }
    }
`