import React from 'react'
import { graphql, useStaticQuery, Link, navigate } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import currencyFormatter from 'currency-formatter'

const Product = props => {

    const data = useStaticQuery(graphql`
        query {
            MAIN_BACKGROUND_COLOR: setting(environmentName: {eq: "MAIN_BACKGROUND_COLOR"}) {
                value
            }
            MENU_COLOR: setting(environmentName: {eq: "MENU_COLOR"}) {
                value
            }
            SHOW_PRICE: setting(environmentName: {eq: "SHOW_PRICE"}) {
                value
            }
            SHOW_EMPTY: setting(environmentName: {eq: "SHOW_EMPTY"}) {
                value
            }
        }
    `)
    const MAIN_BACKGROUND_COLOR = data.MAIN_BACKGROUND_COLOR.value
    const MENU_COLOR = data.MENU_COLOR.value
    const SHOW_PRICE = data.SHOW_PRICE.value === 'true' ? true : false
    const SHOW_EMPTY = data.SHOW_EMPTY.value

    const useStyles = makeStyles({
        card: {
            maxWidth: '100%',
        },
        button: {
            color: `${MAIN_BACKGROUND_COLOR}`
        },
        price: {
            backgroundColor: `${MAIN_BACKGROUND_COLOR}`,
            textTransform: 'uppercase',
            padding: '13px',
            borderRadius: '10px',
            margin: '0px 0px 0px 0px',
            color: `${MENU_COLOR}`,
            textDecoration: 'none',
            textAlign: 'center',
            fontSize: '1.3rem',
            display: 'block',
            border: 'none',
            '&:link': {
                textDecoration: 'none',
                color: `${MENU_COLOR}`
            },
            '&:visited': {
                textDecoration: 'none',
                color: `${MENU_COLOR}`
            },
            '&:hover': {
                textDecoration: 'none',
                color: `${MENU_COLOR}`
            },
            '&:active': {
                textDecoration: 'none',
                color: `${MENU_COLOR}`
            },
        }
    })

    const classes = useStyles()

    let price = (
        <div className={classes.price}>
            {SHOW_EMPTY}
        </div>
    )
    if (props.price > 0) {
        price = (
            <div className={classes.price}>
                {currencyFormatter.format(props.price, { locale: 'pt-BR' })}
            </div>
        )
    }

    const redirect = slug => {
        navigate(`/personalizados/${slug}`)
    }

    let type = 'personalizados';
    if (props.isEvent === true) {
        type = 'festas';
    }

    return (
        <Card className={classes.card}>
            <CardActionArea>
                <Link to={`/${type}/${props.slug}`}>
                    <CardMedia
                        component="img"
                        alt={props.title}
                        image={props.imageThumbnail}
                        title={props.title}
                    />
                </Link>
                <CardContent onClick={() => redirect(props.slug)}>
                    <Typography gutterBottom variant="h5" component="h2">
                        {props.title}
                    </Typography>
                    {SHOW_PRICE ? price : null}
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export default Product